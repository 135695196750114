import React, { useState } from "react";
import axios from "axios";
import Footer from "./Footer";


function Complaint() {
 // Define initial state values for nested objects
 const initialProductQualityState = {
  defectiveProduct: false,
  poorWorkmanship: false,
  damagedDuringTransit: false,
  incorrectSpecifications: false,
  other: false,
  otherDetails: "",
};
const initialServiceDelaysState = {
  delayedDelivery: false,
  missedDeadlines: false,
  appointmentDelays: false,
  longWaitTimes: false,
  otherServiceDelays: false,
  otherServiceDelaysDetails: "",
};
const initialBillingDisputesState = {
  overcharged: false,
  unauthorizedCharges: false,
  incorrectBillingInfo: false,
  doubleBilling: false,
  otherBillingDisputes: false,
  otherBillingDisputesDetails: "",
};
const initialCustomerServiceState = {
  rudeUnprofessionalBehavior: false,
  unhelpfulStaff: false,
  poorCommunication: false,
  lackOfResponse: false,
  otherCustomerServiceIssues: false,
  otherCustomerServiceIssuesDetails: "",
};
const initialWebsiteErrorsState = {
  technicalGlitches: false,
  difficultyNavigating: false,
  websiteCrashes: false,
  paymentErrors: false,
  otherWebsiteErrors: false,
  otherWebsiteErrorsDetails: "",
};
const initialRefundIssuesState = {
  difficultyObtainingRefunds: false,
  delayedRefundProcessing: false,
  refundDenied: false,
  partialRefund: false,
  otherRefundIssues: false,
  otherRefundIssuesDetails: "",
};
const initialCommunicationProblemsState = {
  lackOfOrderUpdates: false,
  noOrderConfirmation: false,
  unableToReachSupport: false,
  poorCommunicationResolution: false,
  otherCommunicationProblems: false,
  otherCommunicationProblemsDetails: "",
};

const [showSuccessMessage, setShowSuccessMessage] = useState(false);
const [formData, setFormData] = useState({
  email: "",
  name: "",
  phoneNumber: "",
  feedback: "",
  productQuality: { ...initialProductQualityState },
  serviceDelays: { ...initialServiceDelaysState },
  billingDisputes: { ...initialBillingDisputesState },
  customerService: { ...initialCustomerServiceState },
  websiteErrors: { ...initialWebsiteErrorsState },
  refundIssues: { ...initialRefundIssuesState },
  communicationProblems: { ...initialCommunicationProblemsState },
});

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // For nested objects like options, serviceDelays, etc.
    if (name.includes(".")) {
      const [parentKey, childKey] = name.split(".");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [parentKey]: {
          ...prevFormData[parentKey],
          [childKey]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      // For regular keys
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("phoneNumber", formData.phoneNumber);
    formDataToSend.append("feedback", formData.feedback);

    // Append productQuality data
    Object.keys(formData.productQuality).forEach((key) => {
      formDataToSend.append(
        `productQuality[${key}]`,
        formData.productQuality[key]
      );
    });

    // Append serviceDelays data
    Object.keys(formData.serviceDelays).forEach((key) => {
      formDataToSend.append(
        `serviceDelays[${key}]`,
        formData.serviceDelays[key]
      );
    });

    // Append billingDisputes data
    Object.keys(formData.billingDisputes).forEach((key) => {
      formDataToSend.append(
        `billingDisputes[${key}]`,
        formData.billingDisputes[key]
      );
    });

    // Append customerService data
    Object.keys(formData.customerService).forEach((key) => {
      formDataToSend.append(
        `customerService[${key}]`,
        formData.customerService[key]
      );
    });

    // Append websiteErrors data
    Object.keys(formData.websiteErrors).forEach((key) => {
      formDataToSend.append(
        `websiteErrors[${key}]`,
        formData.websiteErrors[key]
      );
    });

    // Append refundIssues data
    Object.keys(formData.refundIssues).forEach((key) => {
      formDataToSend.append(`refundIssues[${key}]`, formData.refundIssues[key]);
    });

    // Append communicationProblems data
    Object.keys(formData.communicationProblems).forEach((key) => {
      formDataToSend.append(
        `communicationProblems[${key}]`,
        formData.communicationProblems[key]
      );
    });

    // Append file
    formDataToSend.append("files", file);

    // Perform API call
    try {
      // const response = await axios.get(`${process.env.REACT_APP_SERVER_PRO_URL}api/complaint`);
      console.log("data to send", Object.fromEntries(formDataToSend.entries()));
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_PRO_URL}/api/complaint`,
        formDataToSend,
        { withCredentials: true, headers: { Authorization: `Bearer ${token}` } }
      );
      // console.log("this is the get data",response)

      console.log("post data", response.data);
      setShowSuccessMessage(true);
      // Assuming the API returns some data
      console.log("Form submitted successfully");
   // Clear form data after a short delay
   setTimeout(() => {
    setShowSuccessMessage(false);
    setFormData({
      email: "",
      name: "",
      phoneNumber: "",
      feedback: "",
      productQuality: { ...initialProductQualityState },
      serviceDelays: { ...initialServiceDelaysState },
      billingDisputes: { ...initialBillingDisputesState },
      customerService: { ...initialCustomerServiceState },
      websiteErrors: { ...initialWebsiteErrorsState },
      refundIssues: { ...initialRefundIssuesState },
      communicationProblems: { ...initialCommunicationProblemsState },
    });
    setFile(null);
    window.location.reload(); // Refresh the page after clearing the form
  }, 2000); // Change the delay time as needed
} catch (error) {
  console.error("Error:", error);
}
};

  return (
    <div style={{ fontFamily: "Poppins" }} className="mt-10">
      <form onSubmit={handleSubmit}>
        <div className="w-[80%] mx-auto">
          <h1 className="font-semibold text-2xl md:text-[20px] leading-[50px] md:leading-[70px] p-2 bg-[#F0AF73] text-left ">
            Content Concern Form - Addressing Complaints and Consumer Grievances
          </h1>
        </div>
        <div className="flex flex-col  text-left mx-auto gap-4 w-[80%]">
          <div className="flex mt-11">
            <p className="text-red-700 mr-3 font-bold">*</p>
            <div className="flex flex-col ">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] sm:w-[460px] sm:h-[30px] pl-4  outline-2 border-2 border-black rounded-sm"
                required
              />
            </div>
          </div>
          <div className="flex ">
            <p className="text-red-700 mr-3 font-bold">*</p>
            <div className="flex flex-col">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] sm:w-[460px] sm:h-[30px] pl-4  outline-2 border-2 border-black rounded-sm"
                required
              />
            </div>
          </div>
          <div className="flex ">
            <p className="text-red-700 mr-3 font-bold">*</p>
            <div className="flex flex-col">
              <label>Phone Number</label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="xl:w-[591px] xl:h-[30px] sm:w-[460px] sm:h-[30px] pl-4 outline-2 border-2 border-black rounded-sm"
                required
              />
            </div>
          </div>
          <br />
          <div>
            <label className="block mb-2 leading-10 text-[15px] xl:w-[70%]">
              {" "}
              We value your feedback and are committed to providing excellent
              customer service. If you have encountered any issues or have
              feedback to share, please use the form below to submit your
              complaint. Our team will review your submission and work
              diligently to address your concerns.
            </label>
          </div>
          <br />
          <div>
            <div className="mb-2 font-bold leading-6">Product quality</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="productQuality.defectiveProduct"
                checked={formData.productQuality.defectiveProduct}
                onChange={handleChange}
                className="mr-2"
              />
              Defective product received, not meeting quality standards.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="productQuality.poorWorkmanship"
                checked={formData.productQuality.poorWorkmanship}
                onChange={handleChange}
                className="mr-2"
              />
              Poor workmanship observed, affecting product functionality.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="productQuality.damagedDuringTransit"
                checked={formData.productQuality.damagedDuringTransit}
                onChange={handleChange}
                className="mr-2"
              />
              Goods arrived damaged during transit, impacting usability.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="productQuality.incorrectSpecifications"
                checked={formData.productQuality.incorrectSpecifications}
                onChange={handleChange}
                className="mr-2"
              />
              Product specifications were incorrect, leading to dissatisfaction.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="productQuality.other"
                checked={formData.productQuality.other}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="productQuality.otherDetails"
                value={formData.productQuality.otherDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black xl:ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">serviceDelays</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="serviceDelays.delayedDelivery"
                checked={formData.serviceDelays.delayedDelivery}
                onChange={handleChange}
                className="mr-2"
              />
              Experience delayed delivery, extending beyond promised timeframe.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="serviceDelays.missedDeadlines"
                checked={formData.serviceDelays.missedDeadlines}
                onChange={handleChange}
                className="mr-2"
              />
              Missed deadlines for service completion, causing inconvenience.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="serviceDelays.appointmentDelays"
                checked={formData.serviceDelays.appointmentDelays}
                onChange={handleChange}
                className="mr-2"
              />
              Appointment delays resulting in wasted time and inconvenience.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="serviceDelays.longWaitTimes"
                checked={formData.serviceDelays.longWaitTimes}
                onChange={handleChange}
                className="mr-2"
              />
              Encounter long wait times for assistance or support services.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="serviceDelays.otherServiceDelays"
                checked={formData.serviceDelays.otherServiceDelays}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="serviceDelays.otherServiceDelaysDetails"
                value={formData.serviceDelays.otherServiceDelaysDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Billing Disputes</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="billingDisputes.overcharged"
                checked={formData.billingDisputes.overcharged}
                onChange={handleChange}
                className="mr-2"
              />
              Overcharged for products or services rendered.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="billingDisputes.unauthorizedCharges"
                checked={formData.billingDisputes.unauthorizedCharges}
                onChange={handleChange}
                className="mr-2"
              />
              Unauthorized charges appeared on the bill, leading to confusion.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="billingDisputes.incorrectBillingInfo"
                checked={formData.billingDisputes.incorrectBillingInfo}
                onChange={handleChange}
                className="mr-2"
              />
              Incorrect billing information provided, causing discrepancies.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="billingDisputes.doubleBilling"
                checked={formData.billingDisputes.doubleBilling}
                onChange={handleChange}
                className="mr-2"
              />
              Double billing occurred, resulting in duplicate charges.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="billingDisputes.otherBillingDisputes"
                checked={formData.billingDisputes.otherBillingDisputes}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="billingDisputes.otherBillingDisputesDetails"
                value={formData.billingDisputes.otherBillingDisputesDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Customer Service</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="customerService.rudeUnprofessionalBehavior"
                checked={formData.customerService.rudeUnprofessionalBehavior}
                onChange={handleChange}
                className="mr-2"
              />
              Experienced rude or unprofessional behavior from staff.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="customerService.unhelpfulStaff"
                checked={formData.customerService.unhelpfulStaff}
                onChange={handleChange}
                className="mr-2"
              />
              Staff members were unhelpful or lacked proper assistance.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="customerService.poorCommunication"
                checked={formData.customerService.poorCommunication}
                onChange={handleChange}
                className="mr-2"
              />
              Poor communication from customer service representatives.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="customerService.lackOfResponse"
                checked={formData.customerService.lackOfResponse}
                onChange={handleChange}
                className="mr-2"
              />
              Lack of response to inquiries or complaints submitted.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="customerService.otherCustomerServiceIssues"
                checked={formData.customerService.otherCustomerServiceIssues}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="customerService.otherCustomerServiceIssuesDetails"
                value={
                  formData.customerService.otherCustomerServiceIssuesDetails
                }
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Website Errors</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="websiteErrors.technicalGlitches"
                checked={formData.websiteErrors.technicalGlitches}
                onChange={handleChange}
                className="mr-2"
              />
              Encountered technical glitches or errors while using the website.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="websiteErrors.difficultyNavigating"
                checked={formData.websiteErrors.difficultyNavigating}
                onChange={handleChange}
                className="mr-2"
              />
              Difficulty navigating the website or finding desired information.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="websiteErrors.websiteCrashes"
                checked={formData.websiteErrors.websiteCrashes}
                onChange={handleChange}
                className="mr-2"
              />
              Website crashed or experienced downtime during use.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="websiteErrors.paymentErrors"
                checked={formData.websiteErrors.paymentErrors}
                onChange={handleChange}
                className="mr-2"
              />
              Payment processing errors occurred during checkout process.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="websiteErrors.otherWebsiteErrors"
                checked={formData.websiteErrors.otherWebsiteErrors}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="websiteErrors.otherWebsiteErrorsDetails"
                value={formData.websiteErrors.otherWebsiteErrorsDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div>
            <div className="mb-2 font-bold">Refund Issues</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="refundIssues.difficultyObtainingRefunds"
                checked={formData.refundIssues.difficultyObtainingRefunds}
                onChange={handleChange}
                className="mr-2"
              />
              Difficulty in obtaining refunds for returned products or services.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="refundIssues.delayedRefundProcessing"
                checked={formData.refundIssues.delayedRefundProcessing}
                onChange={handleChange}
                className="mr-2"
              />
              Delayed processing of refund requests, leading to frustration.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="refundIssues.refundDenied"
                checked={formData.refundIssues.refundDenied}
                onChange={handleChange}
                className="mr-2"
              />
              Refund was denied without valid reason or explanation provided.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="refundIssues.partialRefund"
                checked={formData.refundIssues.partialRefund}
                onChange={handleChange}
                className="mr-2"
              />
              Received partial refund amount or refund processed incorrectly.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="refundIssues.otherRefundIssues"
                checked={formData.refundIssues.otherRefundIssues}
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="refundIssues.otherRefundIssuesDetails"
                value={formData.refundIssues.otherRefundIssuesDetails}
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div className="mb-6">
            <div className="mb-2 font-bold">Communication Problems</div>

            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="communicationProblems.lackOfOrderUpdates"
                checked={formData.communicationProblems.lackOfOrderUpdates}
                onChange={handleChange}
                className="mr-2"
              />
              Lack of communication regarding order status or updates.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="communicationProblems.noOrderConfirmation"
                checked={formData.communicationProblems.noOrderConfirmation}
                onChange={handleChange}
                className="mr-2"
              />
              Did not receive order confirmation or shipping notifications.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="communicationProblems.unableToReachSupport"
                checked={formData.communicationProblems.unableToReachSupport}
                onChange={handleChange}
                className="mr-2"
              />
              Unable to reach customer service or support team for assistance.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="communicationProblems.poorCommunicationResolution"
                checked={
                  formData.communicationProblems.poorCommunicationResolution
                }
                onChange={handleChange}
                className="mr-2"
              />
              Poor communication regarding resolution of complaints or issues.
            </label>
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                name="communicationProblems.otherCommunicationProblems"
                checked={
                  formData.communicationProblems.otherCommunicationProblems
                }
                onChange={handleChange}
                className="mr-2"
              />
              Other (please specify):
              <input
                type="text"
                name="communicationProblems.otherCommunicationProblemsDetails"
                value={
                  formData.communicationProblems
                    .otherCommunicationProblemsDetails
                }
                onChange={handleChange}
                className="xl:w-[500px] w-[100px] sm:w-[400px] border-b border-black ml-2 focus:outline-none"
              />
            </label>
          </div>
          <div className="mb-2 font-bold">
            Please upload any supported document If you have any related to your
            concerns or complaint
          </div>
          <div className=" flex flex-col  gap-1">
            <input
              type="file"
              id="fileInput"
              accept="image/*,application/pdf bg-[#D9D9D9]"
              onChange={handleFileChange}
            />
            <br />
            <div className="mb-2 text-xs">
              (Please upload a GIF, PNG, JPG, PDF or JPEG file only and ensure
              that the file is currently not in use.
            </div>
            <p className="text-xs"> [Maximum File Size Limit 2 MB])</p>
          </div>
          <div className="mb-10">
            <button
              type="submit"
              className="bg-[#F58634] hover:bg-[#b16834] text-white font-bold py-2 px-4 rounded mt-4 w-[150px]     "
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <Footer />
       {/* Popup */}
       {showSuccessMessage && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8">
            <p className="text-lg font-bold mb-4 text-black">Your complaint has been successfully submitted!</p>
            <button onClick={() => setShowSuccessMessage(false)} className="bg-[#F58634] hover:bg-[#b16834] text-white font-bold py-2 px-4 rounded inline-flex items-center">
              Close
            </button>
          </div>
        </div>
      )}
      
    </div>
  );
}

export default Complaint;
