import './App.css';
import { Route, Routes } from "react-router-dom";
import Complaint from './pages/Complaint';
import Navbar from './components/Navbar';


function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
        <Route path="/" element={<Complaint />} />
        
      </Routes>
    </div>
  );
}

export default App;