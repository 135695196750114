// src/components/Dropdown.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../components/Dropdown.css';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'; 

const Dropdown = ({ title, items, isSidebar }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  // Inside the Dropdown component
return (
    <div 
        className="dropdown" 
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave}
    >
        <button className="dropdown-btn mr-6 font-medium text-slate-700 text-base">
            {title}
            <KeyboardArrowDownOutlinedIcon/>
        </button>
        {dropdownOpen && (
            <ul className={`dropdown-menu ${dropdownOpen ? 'open' : ''} text-base`}>
                {items.map((item, index) => (
                    <li key={index}>
                        <a href={item.link}>{item.title}</a>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired,
  isSidebar: PropTypes.bool // Whether it's a sidebar dropdown
};

export default Dropdown;
