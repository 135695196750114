// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dropdown.css */
.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    border-radius: 2%;
    margin-left: auto;
    margin-right: auto;
    align-self: start;
    background: #fbf9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: none;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu li {
    list-style: none;
}

.dropdown-menu a {
    display: block;
    padding: 0.5rem 1rem;
    color: #0a0a0a;
    text-decoration: none;
    white-space: nowrap;
}

.dropdown-menu a:hover {
    background: #e7e5e1;
}

/* Dropdown.css */
.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    border-radius: 2%;
    background: #f3f2f2;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: none;
    flex-direction: column;
    gap: 1rem;
    z-index: 1000;
    top: 100%; /* Place the menu below the dropdown */
    left:-50px;/* Align with the left edge of the dropdown */
    
}

.sidebar .dropdown-menu.open {
    display: block;
}

/* Adjust the spacing for dropdown items to accommodate the opened menu */
.sidebar .dropdown-menu.open ~ .dropdown-menu {
    margin-top: 10rem; /* Adjust as needed */
}




`, "",{"version":3,"sources":["webpack://./src/components/Dropdown.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,yCAAyC;IACzC,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,cAAc;IACd,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA,iBAAiB;AACjB;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,yCAAyC;IACzC,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;IACb,SAAS,EAAE,sCAAsC;IACjD,UAAU,CAAC,6CAA6C;;AAE5D;;AAEA;IACI,cAAc;AAClB;;AAEA,yEAAyE;AACzE;IACI,iBAAiB,EAAE,qBAAqB;AAC5C","sourcesContent":["/* Dropdown.css */\n.dropdown {\n    position: relative;\n}\n\n.dropdown-menu {\n    position: absolute;\n    border-radius: 2%;\n    margin-left: auto;\n    margin-right: auto;\n    align-self: start;\n    background: #fbf9f9;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    display: none;\n}\n\n.dropdown:hover .dropdown-menu {\n    display: block;\n}\n\n.dropdown-menu li {\n    list-style: none;\n}\n\n.dropdown-menu a {\n    display: block;\n    padding: 0.5rem 1rem;\n    color: #0a0a0a;\n    text-decoration: none;\n    white-space: nowrap;\n}\n\n.dropdown-menu a:hover {\n    background: #e7e5e1;\n}\n\n/* Dropdown.css */\n.dropdown {\n    position: relative;\n}\n\n.dropdown-menu {\n    position: absolute;\n    border-radius: 2%;\n    background: #f3f2f2;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    display: none;\n    flex-direction: column;\n    gap: 1rem;\n    z-index: 1000;\n    top: 100%; /* Place the menu below the dropdown */\n    left:-50px;/* Align with the left edge of the dropdown */\n    \n}\n\n.sidebar .dropdown-menu.open {\n    display: block;\n}\n\n/* Adjust the spacing for dropdown items to accommodate the opened menu */\n.sidebar .dropdown-menu.open ~ .dropdown-menu {\n    margin-top: 10rem; /* Adjust as needed */\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
